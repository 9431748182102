<template>
  <button @click="$router.go(-1)" class="btn-back"><SystemIcons :type="'backChevron'"/><p>Back to overview</p></button>

  <section>
    <article>
      <div class="row title-row">
        <SystemIcons :type="'category' + cmptMaterialCategory + ''" class="category-icon"/>
        <h1>{{currentMaterial.name}}</h1>
      </div>

      <div class="row margin-b">
        <button v-if="cmptDownloadButton" class="btn-standard btn-white margin-r" @click="downloadMaterial" v-tooltip.bottom="{content: cmptFileSize, distance: 5, delay: 100}"><SystemIcons :type="'download'"/><p>Download U3M</p></button>
        <button v-if="cmptDeleteButton" class="btn-standard btn-white"  @click="deleteMaterial"><SystemIcons :type="'xIcon'" class="x-icon"/><p>Delete</p></button>
      </div>

      <div class="info-container">
        <table class="margin-b" cellspacing="0" cellpadding="0">
          <tr>
            <td>Supplier</td>
            <td>{{currentMaterial.companyName}}</td>
          </tr>
          <tr>
            <td>Uploaded</td>
            <td>{{parseDate(currentMaterial.uploadedOn)}}</td>
            <!-- set locale "de-DE" -->
          </tr>
          <tr>
            <td>Uploaded by</td>
            <td>{{currentMaterial.uploadedBy}}</td>
          </tr>
        </table>

        <h2>Description</h2>
        <div class="margin-b">
          <pre v-if="currentMaterial.description" class="description"> {{currentMaterial.description}} </pre>
          <p v-else>no description provided</p>
        </div>

        <h2>Physics</h2>
        <table class="margin-b" cellspacing="0" cellpadding="0">
          <tr>
            <td>Material Type</td>
            <td class="row"> <SystemIcons :type="'category' + cmptMaterialCategory + ''" class="category-icon"/> {{ cmptMaterialCategory }} </td>
          </tr>
          <tr>
            <td>Weight</td>
            <td v-if="currentMaterial.weight">{{currentMaterial.weight.toFixed(0)}} g/sqm</td>
            <td v-else> - </td>
          </tr>
          <tr>
            <td>Thickness</td>
            <td v-if="currentMaterial.thickness">{{currentMaterial.thickness.toFixed(2)}} mm</td>
            <td v-else> - </td>
          </tr>
          <tr>
            <td>Composition</td>
            <td>
              <p :class="{'composition-list-item' : cmptComposition.length >= 2}" v-for="(item, index) in cmptComposition" :key="index"> {{item}} </p>
            </td>
          </tr>
          <tr>
            <td>Construction</td>
            <td>{{cmptConstruction}}</td>
          </tr>
        </table>

        <h2>Technical Details</h2>
        <table class="margin-b" cellspacing="0" cellpadding="0">
          <tr>
            <td>File type</td>
            <td class="row"> <SystemIcons :type="'uTreeMIcon'" class="category-icon"/> U3M Material </td>
          </tr>
          <tr>
            <td>U3M file size</td>
            <td class="row"> {{cmptFileSize}} </td>
          </tr>
          <tr>
            <td>3D texture width</td>
            <td v-if="currentMaterial.textureWidth" class="row"> {{currentMaterial.textureWidth.toFixed(0)}} mm </td>
          </tr>
          <tr>
            <td>3D texture height</td>
            <td v-if="currentMaterial.textureHeight" class="row"> {{currentMaterial.textureHeight.toFixed(0)}} mm </td>
          </tr>
          <tr>
            <td>3D texture dpi x</td>
            <td v-if="currentMaterial.dpI_X" class="row"> {{currentMaterial.dpI_X.toFixed(0)}} </td>
          </tr>
          <tr>
            <td>3D texture dpi y</td>
            <td v-if="currentMaterial.dpI_Y" class="row"> {{currentMaterial.dpI_Y.toFixed(0)}} </td>
          </tr>
        </table>
      </div>

    </article>
    <aside>
      <transition name="blend-background">
        <div v-if="expandPreview" @click="togglePreviewFullscreen()" class="full-screen-background"></div>
      </transition>

      <div class="material-preview" :class="{'full-screen' : expandPreview}" >
        <button @click="togglePreviewFullscreen()" class="btn-expand" v-tooltip.bottom="{content: expandPreview ? 'Minimize' : 'Maximize', delay: 100}"><SystemIcons :type="expandPreview ? 'minimize' : 'expand'" class="expand-icon"/></button>

        <div class="floating-menu">
          <button class="btn-menu" :class="{'active' : currentModel == 'Cube'}" @click="changeObject(['Cube'], 'Cube_Default')" ><SystemIcons type="cube" class="btn-menu-icon"/></button>
          <button class="btn-menu" :class="{'active' : currentModel == 'Fabric_Ball'}" @click="changeObject(['Fabric_Ball'], 'Fabric_Ball')" ><SystemIcons type="sphere" class="btn-menu-icon"/></button>
          <button class="btn-menu" :class="{'active' : currentModel == 'Fabric_Bolt'}" @click="changeObject(['Fabric_Bolt'], 'Fabric_Bolt')" ><SystemIcons type="fabricBolt" class="btn-menu-icon"/></button>
          <button class="btn-menu" :class="{'active' : currentModel == 'Fabric_Flat'}" @click="changeObject(['Fabric_Flat'], 'Fabric_Flat')" ><SystemIcons type="plane" class="btn-menu-icon"/></button>
          <button class="btn-menu" :class="{'active' : currentModel == 'Shader_Ball'}" @click="changeObject(['Shader_Ball'], 'Shader_Ball')" ><SystemIcons type="shaderBall" class="btn-menu-icon"/></button>
          <!-- <button class="btn-menu refresh-btn" @click="resetDefaultCamera()" @dblclick="updateThumbnail()" v-if="currentMaterial.assemblyPreview != null" v-tooltip.bottom="{content: 'Reset view', distance: 5, delay: 100}"><SystemIcons v-tooltip.bottom="{content: 'dblClick refresh thumbnail', distance: 52, delay: {show: 750, hide: 100}}" type="refreshIcon" class="btn-menu-icon refresh-icon"/></button> -->
          <VMenu :showTriggers="['click']" :distance="10" :placement="'bottom-end'" >
            <button class="dots-menu"><SystemIcons type="menu-dots" class="btn-menu-icon"/></button>

            <template #popper>
              <ul class="v-dropdown-content">
                <li class="v-dropdown-header-big">
                  <p class="title"> View options </p>
                </li>
                <li v-if="currentMaterial.assemblyPreview != null"> <button class="btn-menu refresh-btn" @click="updateThumbnail()" ><SystemIcons type="refreshIcon" class="btn-menu-icon refresh-icon"/> Refresh Thumbnail </button> </li>
                 <!-- <button class="btn-menu" @click="freezeView(true)" style="background:red" ><SystemIcons type="uTreeMIcon" class="btn-menu-icon"/></button>
                  <button class="btn-menu" @click="freezeView(false)" style="background:green"><SystemIcons type="uTreeMIcon" class="btn-menu-icon"/></button> -->

              </ul>
            </template>
          </VMenu>
        </div>
        <!-- <button @click="resetDefaultCamera()" @dblclick="updateThumbnail()" v-if="currentMaterial.assemblyPreview != null" class="btn-reset-view" v-tooltip.bottom="{content: 'Reset view', distance: 5, delay: 100}"><SystemIcons v-tooltip.bottom="{content: 'dblClick refresh thumbnail', distance: 52, delay: {show: 750, hide: 100}}" type="refreshIcon" class="refresh-icon"/></button> -->

        <polyte-player ref="player" :u3m-mat="cmptMaterialId" :mat-switch="cmptExchangeMaterials" start-variant="['Cube']" :onloaded="onSceneIsLoaded()"></polyte-player>

        <SystemIcons :type="'uTreeMIcon'" class="bg-icon"/>

      </div>
    </aside>
  </section>

  <button @click="$router.go(-1)" class="btn-back"><SystemIcons :type="'backChevron'"/><p>Back to overview</p></button>
</template>

<script>
import SystemIcons from '@/components/elements/SystemIcons.vue'
import { displayAuthMixins } from '@/mixins/DisplayAuthMixins.js'
import { parseDateMixins } from '@/mixins/ParseDateMixins.js'

export default {
  name: 'MaterialDetail',

  components: {
    SystemIcons
  },

  mixins:[displayAuthMixins, parseDateMixins],

  props: {
    materialId: String //props: ['id'],
  },
  data () {
    return {
      currentMaterial: {},
      expandPreview: false,
      thumbnailWidth: 400,
      thumbnailHeight: 225,
      currentModel: 'Cube'
    }
  },
  computed: {
      cmptDownloadButton () {
        return this.allowDownloadMaterials()
      },
      cmptDeleteButton () {
        return this.allowDeleteMaterials()
     },
    cmptMaterialId () {
      return this.materialId
    },

    cmptExchangeMaterials(){
      // return "61ea6546bb02d5764580d226," + this.currentMaterial.materialId
      console.log(" cmptExchangeMaterials ---- this.currentMaterial.materialId: ", this.currentMaterial.materialId);
      // return "6268253f3276779c03168c51," + this.currentMaterial.materialId
      return "6294cf2f492aa9c905c0797c," + this.currentMaterial.materialId
    },

    cmptFileSize () {
      return this.formatBytes(this.currentMaterial.packageSize)
    },

    cmptMaterialCategory () {
      return (this.currentMaterial.materialType != null) ? this.currentMaterial.materialType.type : 'Unknown'
      // return 'Other'
      // return 'Artwork'
      // return 'Elastics'
      // return 'Knit'
      // return 'Leather'
      // return 'Metal'
      // return 'Seam'
      // return 'Wood'
      // return 'Woven'
    },

    cmptComposition () {
      //Return as array
      if (this.currentMaterial.composition == null) return ["-"]

      return this.currentMaterial.composition.parts.map(part => `${(part.ratio * 100).toFixed(0)}% - ${part.type}`)
    },

    cmptConstruction () {
      if (this.currentMaterial.construction == null) return " - "

      return this.currentMaterial.construction.type
    }
  },

  methods: {
    setVariant (valArray) {
      var player = this.$refs.player;
      player.setVariant(valArray);
    },

    changeObject (variant, camera) {
      this.currentModel = variant[0]
      var player = this.$refs.player;
      player.setVariant(variant);
      player.setCamera(camera);
    },

    freezeView (freeze, width, height) {
      var player = this.$refs.player;
      player.freezeView(freeze, { width, height} );
    },

    onSceneIsLoaded() {
      //setTimeout necessary, else player not initialized
      setTimeout(async () => {
        if(this.currentMaterial.assemblyPreview != null)
          return
        await this.updateThumbnail ()

      }, 3500);
    },

    updateThumbnail () {
      var player = this.$refs.player;
      if(player == null) //tdo: fix timing problem
        return

      this.setVariant (['Fabric_Bolt'])

      setTimeout(async () => {
        var imageData = player.getSceneAsImage(this.thumbnailWidth, this.thumbnailHeight, 'Fabric_Bolt_Closeup');
        let updateObj = {}
        updateObj.materialId = this.cmptMaterialId
        updateObj.imageData = imageData

        await this.$store.dispatch('updateMaterialPreview', updateObj)

        this.setVariant (['Cube'])
        player.setCamera('Cube_Default')
      }, 200);
      this.currentModel = 'Cube'
    },

    resetDefaultCamera () {
      console.log("Setting Default Camera" );

      var player = this.$refs.player;
      player.setCamera('Cube_Default');
    },

    togglePreviewFullscreen() {
      if(this.expandPreview == false) {
        this.expandPreview = true
        document.documentElement.style.overflow = 'hidden'
      } else {
        this.expandPreview = false
        document.documentElement.style.overflow = 'auto'
      }

      this.$nextTick(() => {
        var player = this.$refs.player;
        player.resizeWindow()
      })
    },

    downloadMaterial(){
      this.$store.dispatch('downloadMaterial', this.currentMaterial)
    },

    async deleteMaterial(){
      await this.$store.dispatch('deletMaterials', [this.currentMaterial.id]) //Note: takes array for possibly multiple deletion
      this.$router.go(-1)
    },

    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  },

  async mounted() {

    if(this.$store.getters.getMaterials.length == 0) //Only load if not currently loaded
      await this.$store.dispatch('fetchMaterials');

    this.currentMaterial = this.$store.getters.getMaterialsById(this.materialId)
    console.log("Got Materials .... ", this.currentMaterial);

    //Add script for Polyte-Player initialisation (after(!) currentMaterial has been set)
    let polytePlayerScript = document.createElement('script')
    polytePlayerScript.setAttribute('src', `${this.$store.getters.getActiveEnvironment.url}PolytePlayerU3M.js`)
    polytePlayerScript.setAttribute('type', 'module')
    document.head.appendChild(polytePlayerScript)
  },
}
</script>

<style scoped lang="scss">

section {
  position: relative;
  margin: 5rem 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0,1fr));
  grid-column-gap: 2rem;
  -moz-column-gap: 2rem;
  column-gap: 2rem;

  @include respond-to('medium') {
    display: block;
  }

  article {
    min-height: 100vh;

    .title-row {
      margin-bottom: .5rem;

      .category-icon {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
      }
    }

    .margin-b {
      margin-bottom: 2rem;
    }
    .margin-r {
      margin-right: .5rem;
    }

    .x-icon {
      width: .65rem;
      height: .65rem;
    }

    .info-container {
      table {
        tr {
          vertical-align: top;
          text-align: left;
          line-height: 1.5rem;
        }
        th, td:first-child {
          min-width: 10rem;
          font-weight: 700;
          word-break: break-all;
          color: var(--color-secondary);
        }
        .category-icon {
          width: 1rem;
          height: 1rem;
          margin-right: .5rem;
        }

        .composition-list-item {
          background-color: var(--bg-secondary);
          margin-bottom: .25rem;
          padding: .15rem .5rem;
          border-radius: .5rem;
        }
      }

      .description {
        background-color: var(--bg-secondary);
        border-radius: .75rem;
        font-family: inherit;
        font-weight: inherit;
        box-sizing: border-box;
        margin: 0 1rem 2rem 0;
        padding: .75rem;
        width: 100%;
        text-align: left;

        word-wrap: break-word; /* IE 5.5+ */
        white-space: pre-line; /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
        white-space: -moz-pre-wrap; /* Mozilla, supported since 1999 */
        white-space: -pre-wrap; /* Opera */
        white-space: -o-pre-wrap; /* Opera */
      }
    }
  }


  aside {
    @include respond-to('medium') {
      pointer-events: none;
      position: absolute;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 20rem;
    }

    .full-screen-background {
      pointer-events: all;
      position: fixed;
      cursor: not-allowed;
      z-index: 1100;
      background-color: var(--modal-background-dim-color);
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
    }

    .material-preview {
      pointer-events: all;
      position: sticky;
      overflow: hidden;
      left: 0;
      top: $header-height + 1rem;
      height: 30rem;
      width: 100%;
      border-radius: .75rem;
      background-color: var(--bg-secondary);

      @include respond-to('medium') {
        bottom: $header-height + 1rem;
        top: calc( 100vh - 12.5rem - 1rem );
      }

      &.full-screen {
        position: fixed;
        z-index: 1120;
        left: 5rem;
        top: 6rem;
        width: calc(100% - 10rem);
        height: calc(100vh - 12rem);
        box-shadow: 0 .75rem 2.5rem .25rem var(--drop-shadow-color);

        @include respond-to('medium') {
          left: 2rem;
          width: calc(100% - 4rem);
        }
        @include respond-to('small') {
          left: 1rem;
          width: calc(100% - 2rem);
        }
      }

      .bg-icon {
        z-index: -10;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 85%;
        height: 85%;
        opacity: .075;
      }

      .floating-menu {
        z-index: 10;
        cursor: pointer;
        position: absolute;
        right: .5rem;
        top: .5rem;
        padding: .5rem;
        background-color: var(--bg-primary);
        border-radius: .5rem;

        @include flexbox;
        @include align-items(center);

        .dots-menu {
          cursor: pointer;
          padding: .5rem;
          margin: 0 0 0 .25rem;
          border: none;
          background: none;
          border-radius: .25rem;

          .btn-menu-icon {
            // width: 1.25rem;
            height: 1.25rem;
            transition: transform .15s ease-out,
                        opacity .15s ease-out;
          }
        }

        .btn-menu {
          cursor: pointer;
          padding: .5rem;
          margin: 0;
          border: none;
          background: none;
          border-radius: .25rem;
          transition: background-color .15s ease-out;

          &:not(:last-of-type) {
            margin: 0 .5rem 0 0;
          }

          &.refresh-btn {
            // margin-left: .5rem;
            background-color: transparent;
          }

          .btn-menu-icon {
            width: 1.25rem;
            height: 1.25rem;
            transition: transform .15s ease-out,
                        opacity .15s ease-out;
          }

          &.active {
            background-color: white;
          }

          &:hover {
            .btn-menu-icon {
              transform: scale(1.1);
            }
            .refresh-icon {
              opacity: 1;
              transform: rotate(90deg);
            }
          }
        }
      }

      .btn-test, .btn-expand, .btn-reset-view {
        z-index: 10;
        cursor: pointer;
        position: absolute;
        padding: 1rem;
        margin: 0;
        border: none;
        background: none;

        &:hover {
          .test-icon {
            transform: scale(1.1);
          }
          .expand-icon {
            transform: scale(1.1);
          }
          .refresh-icon {
            opacity: 1;
            transform: rotate(90deg);
          }
        }

        .test-icon, .expand-icon, .refresh-icon {
          width: 1.25rem;
          height: 1.25rem;
          transition: transform .15s ease-out,
                      opacity .15s ease-out;
        }

        .refresh-icon {
          opacity: .5;
        }
      }

      .btn-test {
        left: 2rem;
        top: 0;
      }

      .btn-expand {
        left: 0;
        top: 0;
      }

      .btn-reset-view {
        right: 0;
        top: 0;
      }

      @include respond-to('medium') {
        height: 12.5rem;
      }
    }
  }
}
.blend-background {
  &-enter-active,
  &-leave-active {
    transition: opacity .2s ease-out;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}
</style>